@import "../../assets/styles/common";

.login {
  &__wrapper {
    h3 {
      font-family: 'exo 2', sans-serif;
      font-size: 17px;
      font-weight: 400;
      letter-spacing: 0.1em;
      color: $textCommonColor;
      text-transform: uppercase;
      margin: 15px 0;
    }

    form {
      width: 100%;
      padding: 36px 24px 0;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      position: relative;

      input {
        font-family: 'exo 2', sans-serif;
        margin: 7px 0;
        font-size: 16px;
        font-weight: 300;
        padding: 15px;
        border-radius: 7px;
        border: 0.5pt solid $buttonColor;
        background: transparent;
        color: $textMainColor;

        &:focus {
          border-color: $textCommonColor;
          outline: none;
        }
      }

      .errorMessage {
        position: absolute;
        top: 173px;
        left: 0;
        justify-content: center;
        display: flex;
        font-family: 'NanumSquare', sans-serif;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: -0.05em;
        width: 100%;
        color: #f1b99d;
      }

      button {
        font-family: 'exo 2', sans-serif;
        margin-top: 43px;
        cursor: pointer;
        padding: 0 0 3px;
        height: 51px;
        border: 0;
        border-radius: 7px;
        font-size: 17px;
        font-weight: 400;
        letter-spacing: 0.1em;
        background-color: $buttonColor;
        color: $textCommonColor;
        text-transform: lowercase;

        &:hover,
        &:focus,
        &:active {
          background-color: $buttonBgColor;
          color: $mainColor;
        }
      }
    }

    .logo {
      font-family: 'NanumSquare', sans-serif;
      font-size: 17px;
      font-weight: 400;
      letter-spacing: -0.05em;
      margin: 0;
      line-height: 60px;
      color: $textMainColor;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      text-align: center;

      strong {
        font-weight: 900;
      }
    }

    .desc {
      font-size: 12px;
      letter-spacing: -0.05em;
      color: $textCommonColor;
      line-height: 1.5;
      margin: 0;
      text-align: center;
    }

    .animate {
        animation:  shake 0.8s  ;
    }

    @keyframes shake{
      0%{
        transform: translateX(0)
      }
      25%{
        transform: translateX(10px);
      }

      50%{
        transform: translateX(-10px);
      }
      100%{
        transform: translateX(0px);
      }
    }

  }
}