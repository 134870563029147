@import "../../assets/styles/common";

.card-modal {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  background-color: #393735;
  border-radius: 6px;
  bottom: -7px;
  right: 35px;
  width: 10%;

  @include bp('mobile'){
    top: 8px;
    right: 30px;
    width: 40%;
    font-size: 15px;
    bottom: unset;
  }

  &__wrapper {
    padding: 10px 0;
  }
  &__wrapper:hover {
    width: 100%;
    border-radius: 6px;
    background-color: #7ac5a2;
  }
}