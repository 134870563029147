@use 'sass:math';

@mixin bp($names: ()) {
  @each $name in $names {
    @if map-has-key($breakpoints, $name) {
      @media #{inspect(map-get($breakpoints, $name))} {
        @content;
      }
    } @else {
      @warn "Unfortunately, no value could be retrieved for `#{$name}`.";
    }
  }
}

// 한줄 말줄임
@mixin ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

