@import '../../assets/styles/common';

.icon{
  z-index: 2;
  position: fixed;
  width: 35px;
  height: 35px;
  margin-left: 20px;
  top: 17px;
  color:white;
  border-radius: 50%;
}