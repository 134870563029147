@import 'reset';
@import 'layout';
@import 'mixins';
@import 'vars';

.pc{
  display: block;
  @include bp('mobile') {
    display: none;
  }
}

.mo{
  display: none;
  @include bp('mobile') {
    display: block;
  }
}

.button-wrapper {
  position: fixed;
  bottom: 0;
  z-index: 9;
  width: 100%;
  background-color: #191817;
  left: 0;
  padding-bottom: 10px;
}

.add-button {
  display: block;
  width: calc(100% - 48px);
  background-color: #393735;
  border: 0;
  border-radius: 7px;
  text-align: center;
  font-family: 'exo 2', sans-serif;
  margin: 0 auto;
  height: 51px;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0.1em;
  color: #999693;
  text-transform: lowercase;

  &:hover,
  &:focus,
  &:active {
    background-color: #7ac5a2;
    color: #191817;
  }
}

.container {
  position: relative;
  margin-bottom: 50px;
}

.card-list {
  position: relative;
  color: #fff;

  &__wrapper {
    position: relative;
    width: 100%;
    display: flex;
    background-color: rgba(57, 55, 53, .3);
    padding: 16px;
    margin: 30px 0 7px;
    border-radius: 7px;

    @include bp('mobile') {
      flex-wrap: wrap;
      margin: 20px 0;
    }

    &.inactive {
      opacity: 25%;
      pointer-events: none;
    }
  }

  &__item {
    width: 20%;

    &--4col{
      width: 25%;
    }

    @include bp('mobile') {
      width: 50%;
      padding: 5px;
    }

    &--full{
      @include bp('mobile'){
        width: 100%;
      }
    }
  }

  &__title {
    margin-bottom: 10px;
    color: #999693;
    font-size: 10px;
  }

  &__name,
  &__author,
  &__publisher,
  &__division,
  {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--red{
      color: #d3302f;
    }
  }

  &__value,
  {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--red{
      color: #d3302f;
    }
  }

  &__modal {
    background-color: red;
  }

  &__more-button {
    @include bp('mobile') {
      position: absolute;
      right: 4px;
      top: 17px;
    }
  }
}

.inner {
  &__wrapper {
    padding: 0 24px 24px;
    position: relative;

    .tooltip {
      display: flex;
      justify-content: center;
      align-items: center;

      &__text {
        font-size: 14px;
        font-weight: 300;
        text-align: center;
        line-height: 1.2;
        letter-spacing: -.05em;
        color: #999693;
        margin: 0;

        @include bp('mobile') {
          font-size: 12px;
        }
      }
      &__block{
        width: 40px;
      }
    }
    .purchase-desc{
      color: $white;
      font-size: 12px;
      margin-top: 10px;
      text-align: center;
      line-height: 1.5;
      &--landscape{
        @media screen and (max-width: 844px) and (orientation: landscape) {
          font-size: 0.4rem;
        }
      }
    }
    .purchase-modal{
      display: flex;
      justify-content: center;
      align-items: center;

      &__li {
          margin-top: 15px;

        &:first-child{
          margin: 0;
        }
      }

      &__text{
        margin-top: 7px;
        padding-left: 5px;
        &.strong{
          font-weight: 600;
          padding: 0;
        }
      }
    }
  }

  &-form {
    padding: 0 24px 24px;

    &__item {
      margin-bottom: 22px;
      display: flex;
      align-items: center;

      .MuiInputBase-root {
        flex: 1;
        color: $white;
      }

      .MuiFormControl-root{
        flex: 1;
      }

      .MuiButtonBase-root{
        color: $white;
      }
    }

    &__label {
      width: 80px;
      font-size: 16px;
      color: #fff;
      line-height: 40px;
      padding: 0 12px 0 0;
    }

    &__date:focus,
    {
      outline: none;
    }

    &__input {
      flex: 1;
    }

    &__search {
      &-container {
        flex: 1 0 auto;
        position: relative;

        .MuiInputBase-root {
          width: 100%;
          background-color: $white;
        }
        .MuiInputBase-input{
          color: $black;
          margin-left: 10px;
          width: 100%;
        }
      }
      &-input {
        width: 100%;
      }
    }

    &__select {
      flex: 1;
      height: 30px;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #444;
      background-color: #fff;
      outline: 0;
      margin: 0;
      border: 1px solid #aaa;
      border-radius: 0.2em;
      box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    }

  }
}

.button-submit {
  font-family: 'exo 2', sans-serif;
  padding: 0 18px 3px;
  height: 51px;
  border: 0;
  border-radius: 7px;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0.1em;
  background-color: #393735;
  color: #999693;
  text-transform: lowercase;
  width: 100%;

  &:hover,
  &:focus,
  &:active {
    background-color: #7ac5a2;
    color: #191817;
  }
}
