// global
html,
body {
  background-color: #191817;
  font-family: 'NanumSquare', sans-serif;
}

.page {
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  color: white;
  overflow: hidden;
  position: absolute;
  top: 0;

  &__wrapper {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
    width: 500px;
  }
}
