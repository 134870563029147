@import "../../assets/styles/common";

@font-face {
  font-family: 'BinggraeSamanco-Bold';
  font-weight: normal;
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-10@1.0/BinggraeSamanco-Bold.woff') format('woff');
  font-style: normal;
}
.main {
  z-index: -1;
  &__wrapper {
    text-align: center;
  }

  &__blockquote {
    font-family: 'BinggraeSamanco-Bold';
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.1em;
    color: $textMainColor;
    text-transform: uppercase;
    white-space: pre-line;
    @include bp('mobile') {
      font-size: 17px;
    }
    @media screen and (max-width: 844px) and (orientation:landscape){
      font-size: 17px;
      letter-spacing: 2.5px;
      margin-bottom: 25px;
      margin-top: 83px;
    }
  }

  &__button {
    font-family: 'exo 2', sans-serif;
    display: block;
    width: 300px;
    cursor: pointer;
    padding: 0 0 3px;
    height: 51px;
    border: 0;
    border-radius: 7px;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0.1em;
    background-color: $buttonColor;
    color: $textCommonColor;
    text-transform: lowercase;
    margin: 43px auto 0 auto;
    &:hover,
    &:focus,
    &:active {
      background-color: $buttonBgColor;
      color: $mainColor;
    }
    @include bp('mobile') {
      font-size: 15px;
    }
    @media screen and (max-width: 844px) and (orientation:landscape){
      margin: 5px auto 0 auto;
    }
  }
}
