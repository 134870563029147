@import '../../assets/styles/common';

.header {
  position: fixed;
  z-index: 1;
  width: 100%;
  margin: 0;
  line-height: 60px;
  text-align: center;
  top: 0;
  left: 0;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: -0.05em;
  background-color: $mainColor;
  color: $textMainColor;
  strong {
    font-weight: 900;
  }
}

.logout {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: -0.05em;
  margin-top: 50px;
  padding: 7px;
  text-align: center;
  color: $textCommonColor;
  strong{
    font-weight: 900;
  }
  button {
    cursor: pointer;
    border: none;
    padding: 2px 5px 5px;
    letter-spacing: -0.1em;
    color: #999693;
    background: none;
    font-size: 14px;
    font-weight: 200;
    text-decoration: underline;
    margin-left: 5px;
    vertical-align: -1px;
  }
}