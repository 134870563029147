.card-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;

  &:first-child {
    padding-top: 0;
  }

  & + & {
    border-top: 1px solid #dee2e6;
    margin-top: 10px;
  }

  .modal-card {
    &--book-name {
      width: 25%;
    }
    &--borrowed-date {
      width: 30%;
    }
    &--return-date {
      width: 30%;
    }
    &--user {
      width: 15%;
    }

    &__value {
      margin-top: 10px;
      &--book-name{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
