.dataResult {
    position: absolute;
    z-index: 1;
    margin-top: -26px;
    width: 100%;
    height: 100px;
    background-color: white;
    overflow: hidden;
    overflow-y: auto;
    top: 58px;
  }
  .dataItem p {
    margin-left: 10px;
  }