// Colors
$mainColor: #191817;
$buttonColor: #393735;
$buttonBgColor: #7ac5a2;
$textCommonColor: #999693;
$textMainColor: #fcfbfa;
$white: #fff;
$black: #000;

$breakpoints: (
        'mobile': (
                max-width: 390px,
        ),
);